<template>
  <router-view/>
</template>
<script>
import { core } from './config/pluginInit'

// import { API } from 'aws-amplify';
// import * as TS from './graphql/queries';
// import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';


// The code here wasn't setting some sort of global auth mode, it was just making a single query with some authMode.
// This would fail because this application was never configured against a backend.


export default {
  name: 'App',
  components: {
  },
  mounted () {
    core.mainIndex()
  }
}
</script>
<!-- Material Icon CSS -->
<style lang="scss">
  @import "assets/scss/style.scss";
</style>
