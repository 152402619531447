// import '@babel/polyfill'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'mutationobserver-shim'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import i18n from './i18n'
import './directives'
import VueGtag from 'vue-gtag'
import axios from 'axios'
import '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

// must configure amplify
// as per https://docs.amplify.aws/start/getting-started/setup/q/integration/react/#install-amplify-libraries
// or https://docs.amplify.aws/start/getting-started/setup/q/integration/vue/#install-amplify-libraries
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
global.Raphael = Raphael

Vue.config.productionTip = false

Vue.use(Vuetify)

Vue.use(VueGtag, {
  config: { id: 'G-9JEGKFX2LV' }
})

Vue.use(Vuelidate)

Vue.use(Toast, {
  position: POSITION.TOP_CENTER,
  transitionDuration: 3000,
  transition: "Vue-Toastification__bounce"
});

Vue.prototype.$http = axios


const vm = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
