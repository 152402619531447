/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_mobile_analytics_app_id": "6a826baf213e4668b063e6b7d0b8cb74",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "6a826baf213e4668b063e6b7d0b8cb74",
            "region": "us-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://zn4odkb5yvfpziuyfe7e5hpfua.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-nuzghw6fovh5botkrgmxouhqou",
    "aws_cognito_identity_pool_id": "us-west-2:28e39e0e-acf5-497b-84ca-f20e0af1fdd1",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_Y9zNGGjWf",
    "aws_user_pools_web_client_id": "2p1lg4ucarqa548aumu4ojurm3",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "scc-thumbnail-bucket141250-proda",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
